<ng-template #load>
  <ng-container *ngIf="!errorFound; else failed">
    <div class="text-center blink mt-5"><span translate>Loading</span>...</div>
  </ng-container>
</ng-template>

<ng-template #failed>
  <div class="text-center mt-5" translate>{{error}}</div>
</ng-template>

<div class="tripspage">

  <div class="page-header">
    <h2 translate>Trips</h2>
    <div class="toolbar" style="justify-content: flex-start;">
      <div ngbDropdown class="custom_dropdown">
        <button class="btn" ngbDropdownToggle *ngIf="domain">{{domain|t}}</button>
        <button class="btn" ngbDropdownToggle *ngIf="!domain" translate>Loading</button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem *ngFor="let d of domains" (click)="domain = d">{{d|t}}</button>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="dd/mm/yyyy" readonly name="dp1" [(ngModel)]="from" ngbDatepicker
            #d1="ngbDatepicker" (dateSelect)="setFrom($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d1.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="dd/mm/yyyy" readonly name="dp2" [(ngModel)]="until" ngbDatepicker
            #d2="ngbDatepicker" (dateSelect)="setUntil($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d2.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div ngbDropdown class="custom_dropdown">
        <button class="btn" ngbDropdownToggle>{{target && !target.label.includes('null') ? target.label : ''}}</button>
        <div ngbDropdownMenu class="max-height-200 overflow-y-auto">
          <button ngbDropdownItem *ngFor="let u of sortUser(users)" (click)="setTargetUser(u)">
            {{ u.system.teltonika && u.system.teltonika.short_name && u.system.teltonika.short_name.length > 0 ? u.system.teltonika.short_name : (u.first_name || '') + ' ' + (u.last_name || '') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="sidebar">

      <ng-container *ngFor="let menuItem of menu">
        <ng-container *ngIf="menuItem.label == domain">

          <div class="menuitem-wrapper">
            <span style="position: absolute; right: 10px; font-size: 14px;" translate>Score</span>
            <div style="margin-top: 4px; margin-bottom: 5px;" *ngFor="let subMenuItem of menuItem.children">
              <div class="boldtitle d-flex" *ngIf="subMenuItem.children.length">
                <div class="flex-grow-1">{{subMenuItem.name|t | translate}}</div>
              </div>
              <div class="subparams">
                <div *ngFor="let sub2menuitem of subMenuItem.children" class="pointer w-100 d-inline-block">
                  <label class="submenu-item">
                    <input *ngIf="isMapOpen" type="checkbox" name="checkbox"
                      value="{{subMenuItem.label + '/' +sub2menuitem.label}}" [disabled]="isDisabled(sub2menuitem)"
                      (change)="onFilterChange($event)"
                      [checked]="isFilterChecked(subMenuItem.label + '/' +sub2menuitem.label)" />
                    <div class="submenu-item-label-wrapper"
                      [ngStyle]="{ 'background-color': subMenuItem.color, 'cursor': 'pointer' }">
                      <span class="flex-span">
                        <img src='{{sub2menuitem.icon}}' class="icon-img" width="24" height="24" />
                        <span *ngIf="selected" class="subparam-count">{{sub2menuitem.count}}</span>
                        <span [ngClass]="{'line-through': isDisabled(sub2menuitem)}">{{sub2menuitem.name|t | translate}}</span>
                      </span>
                      <span *ngIf="selected" class="score">{{ sub2menuitem.score === null ? null : math.floor(sub2menuitem.score) }}</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="!loader && !errorFound; else load">
      <div class="tripscontainer h-100" *ngIf="!isMapOpen">
        <div class="trips-table-wrapper">
          <table>
            <thead style="background-color: white;">
              <tr>
                <th></th>
                <th><span class="events-number">{{trips.length}} <span translate>trips</span></span></th>
                <th style="cursor: pointer" (click)="toggleSort('date')"><span translate>Date</span> <i
                    class="fa fa-sort" aria-hidden="true"></i></th>
                <th><span translate>Depart</span></th>
                <th><span translate>End</span></th>
                <th style="cursor: pointer" (click)="toggleSort('duration')"><span translate>Duration</span><i
                    class="fa fa-sort" aria-hidden="true"></i></th>
                <th style="cursor: pointer" (click)="toggleSort('distance')">Km<i class="fa fa-sort"
                    aria-hidden="true"></i></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let trip of filterTrips() | paginate: { itemsPerPage: pageSize,currentPage: p}"
                id="{{trip.trip_uuid}}"
                [ngClass]="{'selected': selected && trip.trip_uuid===selected.trip_uuid, 'rejected': trip.reject_reason}"
                (click)="selectTrip(trip)">
                <td class="no-header"></td>
                <td>
                  <ng-container *ngIf="!trip.accept_status; else notNull">
                    <img (click)="setMapOpen(true, trip, $event)" src="assets/images/distance_mark.svg" width="40"
                      height="40" />
                  </ng-container>
                  <ng-template #notNull>
                    <ng-container *ngIf="trip.accept_status==='user_rejected' && trip.reject_reason; else notRejected">
                      <img style="color: #f5a623;" (click)="setMapOpen(true, trip, $event)"
                        src="{{rejectReasonIconUrl(trip.reject_reason)}}" width="40" height="40" />
                    </ng-container>
                  </ng-template>
                  <ng-template #notRejected>
                    <div class="c100 center" [class]="'p'+(trip[domain]?.score?.toFixed(0) || 0)" (click)="setMapOpen(true, trip, $event);">
                      <span>{{trip[domain]?.score?.toFixed(0) || 0}}</span>
                      <div class="slice">
                        <div class="bar"></div>
                        <div class="fill"></div>
                      </div>
                      <img *ngIf="(domain == 'safety' && trip.has_safety_video) || (domain == 'eco_efficiency' && trip.has_eco_video)" src="https://upload.wikimedia.org/wikipedia/commons/b/b4/Video_Camera_Icon.svg"
                        style="position: relative;
                        width: 20px;
                        z-index: 1;
                        margin-top: -80px;
                        margin-left: 40px;"
                      />
                    </div>
                  </ng-template>
                </td>
                <td>{{ trip.date }}</td>
                <td>{{ trip.start }}</td>
                <td>{{ trip.end }}</td>
                <td>{{ trip.durationStr }}</td>
                <td>{{ trip.distance }}</td>
                <td class="no-header"></td>
              </tr>
            </tbody>
          </table>
          <table *ngIf="(tripSub && tripSub.closed && filterTrips().length === 0) || !tripSub">
            <tbody class="">
              <tr>
                <div class="text-center mt-5"><span translate>No Trips to show</span></div>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="trips-table-paginator">
          <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)"
            directionLinks="true" responsive="false" previousLabel='<' nextLabel='>'
            screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
            screenReaderCurrentLabel="You're on page">
          </pagination-controls>
          <div style="margin-left:10px; padding-top: 7px;padding-bottom: 16px;"><span translate>{{'pagination' |
              translate: { currentPage: p, totalPage: getPages()} }}</span></div>
        </div>

      </div>

      <div class="map-container" *ngIf="isMapOpen">
        <div class="map-container-info-bar" *ngIf="selected">
          <div>
            <img src="assets/images/menu-svgrepo-com.svg" (click)="isMapOpen=false" width="24" />
          </div>
          <div style="width: 24px;">
            <img *ngIf="selected.trip_uuid != filterTrips()[0].trip_uuid" src="assets/images/right-arrow.svg" (click)="openPreviousMap($event)" width="24" style="transform: scaleX(-1); -webkit-transform: scaleX(-1);"/>
          </div>
          <div class="event-date">
            <strong>{{ selected.date }}</strong>
            <small class="map-header-title">Date</small>
          </div>
          <div class="event-start">
            <strong>{{ selected.start }}</strong>
            <small class="map-header-title" translate>Depart</small>
          </div>
          <div class="event-end">
            <strong>{{ selected.end }}</strong>
            <small class="map-header-title" translate>End</small>
          </div>
          <div class="event-duration">
            <strong>{{ selected.durationStr }}</strong>
            <small class="map-header-title" translate>Duration</small>
          </div>
          <div class="event-distance">
            <strong>{{ selected.distance }}</strong>
            <small>KM</small>
          </div>
          <div style="width: 24px;">
            <img *ngIf="selected.trip_uuid != filterTrips()[filterTrips().length-1].trip_uuid" src="assets/images/right-arrow.svg" (click)="openNextMap($event)" width="24" />
          </div>
        </div>
        <app-trip-map [selected]="selected" [domain]="domain" [status]="selectedItems" [flattenLegend]="flattenLegend"
          [filterLegends]="filterLegends" style="flex: 1;" (mapClick)="mapClickHandler($event)" (tripUpdate)="tripUpdateHandler()"></app-trip-map>
        <div class="options-trigger" (click)="toggleOptions()"></div>
        <div class="options" [hidden]="!optShown">

          <b translate>Status</b>
          <hr>
          <ng-container *ngFor="let l of statuses">
            <label class="checkbox">
              <input type="checkbox" [(ngModel)]="l.value" (change)="statusChanged(l)" name="status">
              <div class="checkmark"></div>
              <b>{{l.label}}</b>
            </label>
          </ng-container>
        </div>
        <!-- <div id="map"></div> -->
      </div>
    </ng-container>
  </div>


</div>

<ng-template #rt let-r="result" let-t="term">
  <div class="text-left">
    <i style="width: 30px; text-align: center; color: silver" class="fas"
      [ngClass]="{'fa-users': r.prefix == 'Group', 'fa-user': r.prefix == 'User'}"></i>
    <ngb-highlight [result]="r.label" [term]="t"></ngb-highlight>
  </div>
</ng-template>
