import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {Language, LanguageService} from '../../services/language.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  login_error: boolean = false;
  error: boolean = false;
  model: { username: string, password: string } = {username: '', password: ''};
  projects: any[];
  sub: Subscription;
  token: string;
  loggedIn: boolean = false;
  email: string;

  languages: Language[];
  lang: any = 'en';

  constructor(
    public dataService: DataService,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private languageService: LanguageService,
  ) {
    this.languages = this.languageService.getListOfLanguages();
  }

  ngOnInit(): void {
    this.lang = this.languageService.getCurrentLanguage().code;
  }

  changeLang(event): void {
    this.languageService.switchLanguage(this.lang);
  }

  login() {
    this.sub = this.authService.login(this.model.username, this.model.password)
      .subscribe(
        user => {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.dataService.getProjectList()
            .subscribe(
              data => {
                this.dataService.user = data.user;
                this.email = data.user.email;
                localStorage.setItem('user', JSON.stringify({first_name: data.user.first_name, last_name: data.user.last_name}));
                localStorage.setItem('access_token', user.access_token);
                this.dataService.projects = data.projects.filter((p: any) => {
                  const containAtrium = p.systems.find(s => s.system_type == 'atrium');
                  return !containAtrium;
                });
                if (this.authService.isSuperAdmin()) {
                  console.log('super admin logged in');
                  this.router.navigate(['/admin']);
                }
                else {
                  console.log(this.model.username + ' logged in');
                  this.router.navigate(['/choose-project']);
                }
              },
              error => {
                if (error.status == 401) {
                  localStorage.removeItem('access_token');
                  localStorage.removeItem('refresh_token');
                  this.loggedIn = false;
                }
              }
            );
        },
        error => {
          this.login_error = true;
        }
      )
  }

  verify() {
    this.sub = this.authService.verifyEmail(this.model.username, this.model.password, this.token)
      .subscribe(
        (tokens: {access_token: string, refresh_token: string}) => {
          localStorage.setItem('access_token', tokens.access_token);
          localStorage.setItem('refresh_token', tokens.refresh_token);
          if (this.authService.isSuperAdmin()) {
            console.log('super admin logged in');
            this.router.navigate(['/admin']);
          }
          else {
            console.log(this.model.username + ' logged in');
            this.router.navigate(['/choose-project']);
          }
        },
        error => {
          if (error.status == 403) {
            console.log('error code: ', error.status);
          } else {
            this.error = true;
          }
        }
      )
  }
}
