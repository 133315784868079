import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from '../../services/auth.service';
import {WebConfigurationService} from '../../services/web-configuration.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MenuItem} from '../../helpers/interfaces';
import {LanguageService} from '../../services/language.service';
import {DataService} from '../../services/data.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
  menuItems: MenuItem[] = [
    {
      label: 'Drivers',
      url: '/',
      exact: true,
      configProperty: 'drivers',
    },
    {
      label: 'Leaderboards',
      url: '/leaderboards',
      exact: false,
      configProperty: 'leaderboards',
    },
    {
      label: 'Results',
      url: '/results',
      exact: false,
      configProperty: 'results',
    },
    {
      label: 'Gamification',
      url: '/gamification',
      exact: false,
      configProperty: 'gamification',
    },
    {
      label: 'FORUM',
      url: '/forum',
      exact: false,
      configProperty: 'forum',
    },
    {
      label: 'Contact us',
      url: '/contact',
      exact: false,
    },
  ];

  private destroy$ = new Subject<void>();
  private system = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private configuration: WebConfigurationService,
    public dataService: DataService,
    private storage: StorageService,
    // Don't remove languageService, it's used for selecting the language in constructor
    private languageService: LanguageService,
  ) {
    this.system = this.dataService.project.systems[0].system_type;
    if (this.system === 'teltonika'){
      this.menuItems.splice(1, 0, {
        label: 'Position',
        url: '/position',
        exact: false,
        configProperty: 'position',
      });
    }
    this.configuration.webConfiguration$
      .pipe(takeUntil(this.destroy$)).subscribe((config) => {
      if (!config) {
        return;
      }
      this.menuItems.forEach((item) => {
        const property = config[item.configProperty];
        if (!property) {
          item.disabled = false;
          return;
        }
        if (property.status === 'disabled') {
          item.disabled = true;
          return;
        }
        item.disabled = false;
      });
    });
  }

  public isMenuCollapsed = true;

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  chooseProject(project: any) {
    this.dataService.getProjectConfiguration(project.project_id).subscribe(result => {
      Object.keys(result.schema).map(key => {
        const subparams = this.findParams(result.schema[key], 'subparams');
        if (typeof subparams === 'object' && Object.values(subparams).length === 0) {
          delete result.schema[key];
        }
      });
      this.storage.set('project', Object.assign({ configuration: result }, project));
      this.dataService.project = null;
      location.reload();
    });
  }

  findParams(object, key) {
    let value;
    let that = this;
    Object.keys(object).some(function (k) {
      if (k === key) {
        value = object[k];
        return true;
      }
      if (object[k] && typeof object[k] === 'object') {
        value = that.findParams(object[k], key);
        return value !== undefined;
      }
    });
    return value;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  getProjectlogo() {
    const project = JSON.parse(localStorage.getItem('project'));

    if (!project) {
      return '/assets/images/logo_horizontaal_RGB.svg';
    }

    const { configuration } = project;

    return configuration.project_image ? configuration.project_image : '/assets/images/logo_horizontaal_RGB.svg';
  }

  isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }

  getUserName() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : user.email;
  }

  getProjectName() {
    return JSON.parse(localStorage.getItem('project')).project_name;
  }

  menuItemClick(event: MouseEvent, item: MenuItem) {
    if (item.disabled) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

}
