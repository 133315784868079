import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import {from, of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {DataService} from '../../../../services/data.service';
import {CreateUser, ModalType, Order, Sort, User} from '../../../../helpers/interfaces';
import {AdminUserModalComponent} from '../../modals/user-modal/admin-user-modal.component';
import {ConfirmationComponent} from '../../../_shared/confirmation/confirmation.component';
import {AttachProjectLeaderModalComponent} from '../../modals/attach-project-leader-modal/attach-project-leader-modal.component';
import {AlertPopupComponent} from '../../../_shared/alert-popup/alert-popup.component';

@Component({
  selector: 'admin-project-leaders',
  templateUrl: './project-leaders.component.html',
  styleUrls: ['./project-leaders.component.scss'],
})
export class ProjectLeadersComponent implements OnInit, OnDestroy {
  project_id: string;

  leaders: User[];

  sort: Sort = {
    field: 'project_name',
    order: Order.none,
  };
  private destroy$ = new Subject();

  constructor(private route: ActivatedRoute, private dataService: DataService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    // init project id
    this.project_id = this.route.snapshot.paramMap.get('id');

    // fetch project leaders
    this.fetchProjectLeaders();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  fetchProjectLeaders() {
    this.dataService.getUsers(this.project_id, ['projectleader'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((leaders) => {
        this.leaders = leaders;
      });
  }

  createUser(createUser: CreateUser, type: ModalType = ModalType.creating) {
    const project = {
      project_id: this.project_id,
      role: 'projectleader',
    };

    this.dataService.createUser({...createUser, project})
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        const {user_id} = data;

        if (user_id) {
          if (type === ModalType.creating) {
            this.fetchProjectLeaders();
            this.inviteUser(data);
          } else if (type === ModalType.updating) {
            this.fetchProjectLeaders();
          }
        }
      }, (error) => {
        const ref = this.modalService.open(AlertPopupComponent, { centered: true });
        ref.componentInstance.title = 'Error';
        ref.componentInstance.msg = typeof error.error.message === 'string' ? error.error.message : 'An error occurred while creating user.'
        return from(ref.result);
      } );
  }

  inviteUser(leader: User) {
    this.dataService
      .inviteUser({
        project_id: this.project_id,
        user_id: leader.user_id,
        invitation_type: 'mail',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  openModal(modalType: string, leader?: User) {
    const modalRef = this.modalService.open(AdminUserModalComponent);
    modalRef.componentInstance.setModalType(modalType as ModalType);
    modalRef.componentInstance.isProjectLeader = true;
    if ((modalType as ModalType) === ModalType.updating) {
      modalRef.componentInstance.setUserForm(leader);
    }

    from(modalRef.result)
      .pipe(catchError((err) => of('no')))
      .subscribe((result) => {
        if (result && result !== 'no') {
          this.createUser(result as CreateUser, modalType as ModalType);
        }
      });
  }

  getLeaders() {
    if (this.sort.order === Order.none) {
      return this.leaders;
    }

    return _.orderBy(this.leaders, [this.sort.field], [this.sort.order]);
  }

  getSafeTID(leader: User) {
    if (!leader.system || !leader.system.system_type) {
      return null;
    }

    const data = leader.system[leader.system.system_type];
    return data && typeof data === 'object' ? data.short_id : null;
  }

  getModalTitle(modalType: ModalType) {
    return modalType === ModalType.creating ? 'Add Project Leader' : 'Edit Project Leader';
  }

  toggleSort(field: string) {
    if (this.sort.order === Order.none || this.sort.field !== field) {
      this.sort = {
        field,
        order: Order.asc,
      };

      return;
    }

    switch (this.sort.order) {
      case Order.asc:
        this.sort.order = Order.desc;
        break;
      case Order.desc:
        this.sort.order = Order.none;
        break;
    }
  }

  getSortClass(field: string) {
    if (this.sort.field !== field || this.sort.order === Order.none) {
      return {
        'fa-sort': true,
        'text-muted': true,
      };
    }

    if (this.sort.order === Order.asc) {
      return {
        'fa-sort-down': true,
      };
    } else if (this.sort.order === Order.desc) {
      return {
        'fa-sort-up': true,
      };
    }
  }

  deleteUser(leader: any) {
    const ref = this.modalService.open(ConfirmationComponent);
    const component = ref.componentInstance as ConfirmationComponent;
    component.title = `Delete Project Leader`;
    component.message = `Are you sure you want to delete project leader "${leader.first_name} ${leader.last_name}"?`;
    ref.result.then(result => {
      this.dataService.deleteProjectMember({
        project_id: this.project_id,
        user_id: leader.user_id,
        role: 'projectleader',
      }).pipe(
        takeUntil(this.destroy$)
      )
        .subscribe(() => {
          this.fetchProjectLeaders();
        });
    }).catch(() => {
    });
  }

  attachProjectLeader() {
    const modalRef = this.modalService.open(AttachProjectLeaderModalComponent);
    modalRef.componentInstance.existingLeaders = this.leaders;
    console.log(this.leaders);
    from(modalRef.result)
      .pipe(catchError((err) => of('no')))
      .subscribe((result) => {
        if (result && result !== 'no') {
          this.dataService.attachProjectLeader({
            project_id: this.project_id,
            user_id: result,
            role: 'projectleader',
          }).pipe(
            takeUntil(this.destroy$)
          ).subscribe((response) => {
            this.fetchProjectLeaders();
          }, (error) => {
            const ref = this.modalService.open(AlertPopupComponent, { centered: true });
            ref.componentInstance.title = 'Error';
            ref.componentInstance.msg = typeof error.error.message === 'string' ? error.error.message : 'An error occurred while attaching project leader.'
            return from(ref.result);
          });
        }
      });
  }
}
