import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router, CanActivateChild} from '@angular/router';
import { Observable, of } from 'rxjs';
import {DataService} from './data.service';
import {AuthService} from './auth.service';
import { map } from "rxjs/operators";
import { StorageService } from './storage.service';

@Injectable()
export class UserGuard implements CanActivate, CanActivateChild, Resolve<any> {

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let access_token = localStorage.getItem('access_token');
    let refresh_token = localStorage.getItem('refresh_token');
    // let access_token_expired = access_token && !this.jwtReaderService.isTokenExpired(access_token) ? false : true;
    // let refresh_token_expired = refresh_token && !this.jwtReaderService.isTokenExpired(refresh_token) ? false : true;

    if (!access_token)
      this.router.navigate(['login']);

    if (!this.authService.isSuperAdmin() && !(this.dataService.project && this.dataService.project.configuration)) {
      this.router.navigate(['/choose-project'], {queryParams: {'backurl': state.url}});
      return false;
    }

    if (this.authService.isSuperAdmin() && this.router.url === '/choose-project') {
      this.router.navigate(['/admin']);
      return false;
    }

    return true;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.authService.isSuperAdmin()) {
      const superAdmin = {
        first_name: 'super',
        last_name: 'admin',
        email: 'superuser@mail.com'
      };
      this.dataService.user = superAdmin;
      this.storageService.set('user', superAdmin);

      return of(true);
    }

    if (!this.dataService.user || !this.dataService.projects) {
      this.dataService.getProjectList()
        .subscribe(
          config => {
            this.dataService.user = config.user;
            this.dataService.projects = config.projects.filter((p: any) => {
              const containAtrium = p.systems.find(s => s.system_type == 'atrium');
              return !containAtrium;
            });
            return true;
          },
          error => {
            if (error.status == 400) {
              localStorage.removeItem('access_token');
              localStorage.removeItem('refresh_token');
              localStorage.removeItem('currentUser');
              localStorage.removeItem('user');
              this.router.navigate(['login']);
              return false;
            }
          }
        )
    }

    return of(true);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(next, state);
  }
}
