import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DataService} from 'src/app/services/data.service';
import {WebConfigurationService} from '../../services/web-configuration.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MenuItem} from '../../helpers/interfaces';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit, OnDestroy {
  tab = 0;
  menuItems: MenuItem[] = [
    {
      url: 'trips',
      label: 'Trips',
      configProperty: 'trips',
    },
    {
      url: 'scores',
      label: 'Scores',
      configProperty: 'scores',
    },
    {
      url: 'reports',
      label: 'Reports',
      configProperty: 'reports',
    },
    {
      url: 'videos',
      label: 'Videos',
    },
    {
      url: 'incidents',
      label: 'Incidents',
    }
  ];
  system: string;
  private destroy$ = new Subject<void>();

  constructor(private renderer: Renderer2,
              private dataService: DataService, private configuration: WebConfigurationService) {
    this.configuration.webConfiguration$
      .pipe(takeUntil(this.destroy$)).subscribe((config) => {
      // if (!config.results) {
      //   return;
      // }
      // this.menuItems.forEach((item) => {
      //   const property = config.results.subsections[item.configProperty];
      //   if (!property) {
      //     item.disabled = false;
      //     return;
      //   }
      //   if (property.status === 'disabled') {
      //     item.disabled = true;
      //     return;
      //   }
      //   item.disabled = false;
      // });
    });
  }

  isShowingTab(title) {
    return (title !== 'Videos' || (title === 'Videos' && this.system === 'teltonika')) && (title !== 'Incidents' || (title === 'Incidents' && this.system === 'samsara'));
  }

  ngOnInit(): void {
    this.system = this.dataService.project.systems[0].system_type;
    this.renderer.addClass(document.body, 'result-page');
  }

  setTab(tabNumber: number) {
    this.tab = tabNumber;
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'result-page');
    this.destroy$.next();
    this.destroy$.complete();
  }

  menuItemClick(event: MouseEvent, item: MenuItem) {
    if (item.disabled) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }
}
