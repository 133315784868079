import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from "rxjs/operators";
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbTypeaheadSelectItemEvent,
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
import 'moment-duration-format';
import * as L from 'leaflet';
import { gridLayer, latLng, tileLayer } from 'leaflet';
import 'leaflet-editable';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-routing-machine';
import 'leaflet-geometryutil';
import 'leaflet.markercluster';
import { CustomAdapter, CustomDateParserFormatter } from "../../_shared/ngbdatepickeradapter/datepicker-adapter";
import { VideoInfoComponent } from './video-info/video-info.component';


type ChartCfg = {
  id: string,
  api: string,
  label: string,
  params: any
};

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class VideosComponent implements OnInit {
  //todo: dynamic chart shrink on desktop

  sort = {
    column: '',
    asc: 1
  };

  indgroup: number = 1;

  targetAudience: string = 'Target Audience';
  timeInterval: string = 'Time Interval';

  generalMenu = [];

  from: NgbDate;
  until: NgbDate;

  system: string;

  map: L.DrawMap;
  menu = [];

  leafletOptions: any;
  tiles: any[] = [];

  videos: any[] = [];
  selected: any = null;

  layergroup: L.LayerGroup;
  routing: L.Routing.Control;

  filterLegends = [];

  users: any[] = [];
  groups: any[] = [];
  search: (text: Observable<string>) => Observable<readonly any[]>

  target: any;

  private _domain: string;
  private _type: string;

  domains: string[] = [];
  loader: boolean = true;
  error: string = "";
  errorFound: boolean = false;

  pageSize = 10;
  p = 1;

  statuses = [];
  selectedItems = ['high'];

  userConfiguration = {};

  videoSub: Subscription;

  optShown = false;
  selUserId = null;
  staredOnly = false;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private calendar: NgbCalendar,
    private modal: NgbModal,
  ) { }

  ngOnInit(): void {

    this.statuses = [
      { value: true, name: 'high', label: 'High' },
      { value: false, name: 'medium', label: 'Medium' },
    ];
    this.system = this.dataService.project.systems[0].system_type;
    this.search = (text$: Observable<string>) => text$.pipe(
      // debounceTime(200),
      distinctUntilChanged(),
      // filter(term => term.length >= 2),
      map(term => {

        if (term.length < 2) {
          return [{ prefix: 'Group', label: `All`, value: { community_id: null, user_id: null } }];
        }

        const users = this.users.filter(x => x).map(user => ({ prefix: 'User', label: `${ user.system.teltonika && user.system.teltonika.short_name && user.system.teltonika.short_name.length > 0 ? user.system.teltonika.short_name : (user.first_name || '') + ' ' + (user.last_name || '') }`, value: { user_id: user.user_id, community_id: null } }));
        const groups = this.groups.filter(x => x).map(group => ({ prefix: 'Group', label: `${group.name}`, value: { community_id: group.id, user_id: null } }));
        return [...groups, ...users].filter(item => item.label.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
      })
    )
    this.dataService.getProjectConfigurationGeneral().pipe(
      tap(config => this.init(config)),
      switchMap(() => this.loadUsers()),
      switchMap(() => this.route.queryParams)
    ).subscribe(params => {
      this.loader = true
      this._type = params.type || 'general';
      this._domain = params.domain || this.domains[0];

      this.until = this.str2NgbDate(params.until, this.calendar.getToday());
      this.from = this.str2NgbDate(params.from, this.calendar.getPrev(this.calendar.getToday(), 'm', 1));

      if (!params.user_id && !params.community_id) {

        if (this.users.length) {
          const user = this.users[0];
          this.target = { prefix: 'User', label: `${ user.system.teltonika && user.system.teltonika.short_name && user.system.teltonika.short_name.length > 0 ? user.system.teltonika.short_name : (user.first_name || '') + ' ' + (user.last_name || '') }`, value: { user_id: user.user_id, community_id: null } };
          this.dataService.getUserConfiguration(user.user_id).subscribe(result => {
            this.getUserSubParamConf(result);
          })
        }

      } else if (params.user_id) {
        const user = this.users.find(u => u.user_id == params.user_id);
        this.target = { prefix: 'User', label: `${ user.system.teltonika && user.system.teltonika.short_name && user.system.teltonika.short_name.length > 0 ? user.system.teltonika.short_name : (user.first_name || '') + ' ' + (user.last_name || '') }`, value: { user_id: user.user_id, community_id: null }
      };
        this.dataService.getUserConfiguration(user.user_id).subscribe(result => {
          this.getUserSubParamConf(result);
        })
      } else if (params.community_id) {
        const group = this.groups.find(g => g.id == params.community_id);
        this.target = { prefix: 'Group', label: `${group.name}`, value: { community_id: group.id, user_id: null } };
      }
      this.errorFound = false;
      if (this.users.length) {
        this.selUserId = params.user_id ? params.user_id : this.users[0].user_id;
        this.getVideos()
      }
      else {
        this.loader = false;
      }
    });
  }

  getVideos() {
    this.videoSub = this.dataService.getTeltonikaVideos(
      this.selUserId,
      this.ngbDate2Str(this.from),
      this.ngbDate2Str(this.until)
    ).subscribe(result => {
      this.videos = this.sortItems('date', -1, result.map(e => {
        return {
          ...e,
          timeOfEvent: moment(e.video_start_date).format('DD/MM/YYYY HH:mm'),
          driver: e.user_short_name,
          durationStr: moment.duration(e.duration, 'seconds').format('hh:mm:ss')
        }
      }));

      this.menu = this.menu.map(m => {
        m.children = m.children.map(mm => {
          mm.children = mm.children.map(mmm => {
            mmm.count = (this.videos.filter(v => v.subparam === mmm.label)).length;
            return mmm;
          })
          return mm;
        })
        return m;
      })
      this.loader = false;

    },
      error => {
        this.error = error.error.message;
        this.loader = false;
        this.errorFound = true;
      })
  }

  filterStarOnly() {
    this.staredOnly = !this.staredOnly;
  }

  getUserSubParamConf(conf) {
    Object.keys(conf).forEach(key => {
      this.userConfiguration[key] = {};
      if (typeof conf[key] === 'object' && 'params' in conf[key]) {
        Object.keys(conf[key]['params']).forEach(param => {
          this.userConfiguration[key][param] = {};
          if ('subparams' in conf[key]['params'][param]) {
            Object.keys(conf[key]['params'][param]['subparams']).forEach(subparam => {
              this.userConfiguration[key][param][subparam] = conf[key]['params'][param]['subparams'][subparam]['status'] === "enabled";
            });
          }
        });
      }
    });
  }

  pageChanged($event: number) {
    this.p = $event;
  }

  statusChanged(status) {
    const temp = [];
    this.statuses.forEach(e => {
      if (e.value) {
        temp.push(e.name);
      }
    });
    this.selectedItems = temp;
  }

  getPages() {
    return Math.ceil(this.filterVideos().length / this.pageSize);
  }

  mapClickHandler(event) {
    if (this.optShown) this.optShown = false;
  }

  toggleOptions() {
    this.optShown = !this.optShown;
  }

  selectVideo(video) {
    this.selected = video;
  }

  setParamInfo() {
    this.menu.forEach(m => {
      m.children.forEach(mm => {
        mm.children.forEach(mmm => {
          mmm.count = this.selected.events ? (this.selected.events || []).filter(e => e.param == mm.label && e.subparam == mmm.label).length : 0;
          mmm.score = this.selected[this.domain][mm.label][mmm.label].score ? this.selected[this.domain][mm.label][mmm.label].score : 0;
        })
      })
    })
  }

  setAllfilters() {
    let temp = [...this.filterLegends];
    this.menu.forEach(menuItem => {
      if (menuItem.children.length) {
        menuItem.children.forEach(submenuItem => {
          submenuItem.children.forEach(item => {
            if (item.count) {
              const key = `${this.domain}/${submenuItem.label}/${item.label}`;
              temp.push(key);
            }
          });
        });
      }
    });
    this.filterLegends = temp;
  }

  filterVideos() {
    return this.sortItems(this.sort.column, this.sort.asc, this.videos);
  }

  onFilterChange(event) {
    const params = event.target.value.split('/');
    const key = `${this.domain}/${params[0]}/${params[1]}`;
    let temp = [...this.filterLegends];
    if (event.target.checked && temp.indexOf(key) === -1) {
      temp.push(key);
    }
    else if (!event.target.checked && temp.indexOf(key) > -1) {
      const index = temp.indexOf(key);
      temp.splice(index, 1);
    }
    this.filterLegends = temp;

  }

  isFilterChecked(value) {
    const params = value.split('/');
    const key = `${this.domain}/${params[0]}/${params[1]}`;
    if (this.filterLegends.indexOf(key) === -1) {
      return false;
    }
    return true;
  }

  private str2NgbDate(str: string, def: NgbDate) {
    if (str) {
      const dd = str.split('/');
      return {
        year: parseInt(dd[2]),
        month: parseInt(dd[1]),
        day: parseInt(dd[0])
      } as NgbDate;
    }

    return def;
  }

  private ngbDate2Str(date: NgbDate) {
    return `${date.year}-${date.month}-${date.day}`;
  }

  starringVideo(video) {
    if (video.starred) {
      this.dataService.unsetTeltonicaVideoStar(
        video.request_uuid
      ).subscribe(result => {
        this.getVideos();
      },
        error => {
          this.error = error.error.message;
          this.errorFound = true;
        })
    } else {
      this.dataService.setTeltonicaVideoStar(
        video.request_uuid
      ).subscribe(result => {
        this.getVideos();
      },
        error => {
          this.error = error.error.message;
          this.errorFound = true;
        })
    }
  }

  private init(config: any) {
    const schema = this.dataService.project.configuration.schema;

    for (const key in config[this.system]) {
      const item = config[this.system][key];
      const id = `general/${key}`;

      this.generalMenu.push(this.menuItem(id, item.display_name, item.display_name));
    }

    if (schema.safety) {
      this.domains.push('safety');
      const safety = this.menuItem('safety', 'safety', 'safety');
      this.menu.push(safety);

      for (const param in schema.safety.params) {
        const id = `safety/${param}`;

        const paramMenu = this.menuItem(id, param, schema.safety.params[param].name, true, null, schema.safety.params[param].color);
        safety.children.push(paramMenu);
        Object.keys(schema.safety.params[param].subparams).map(subparam => {
          const id = `safety/${param}/${subparam}`;
          paramMenu.children.push(this.menuItem(id, subparam, schema.safety.params[param].subparams[subparam].name, false, schema.safety.params[param].subparams[subparam].icon));
        });
      }
    }

    if (schema.eco_efficiency) {
      this.domains.push('eco_efficiency');
      const eco = this.menuItem('eco', 'eco_efficiency', 'eco_efficiency');
      this.menu.push(eco);

      for (const param in schema.eco_efficiency[this.system].params) {
        const id = `eco/${param}`;

        const paramMenu = this.menuItem(id, param, schema.eco_efficiency[this.system].params[param].name, true, null, schema.eco_efficiency[this.system].params[param].color);
        eco.children.push(paramMenu);

        Object.keys(schema.eco_efficiency[this.system].params[param].subparams).map(subparam => {
          const id = `eco/${param}/${subparam}`;
          paramMenu.children.push(this.menuItem(id, subparam, schema.eco_efficiency[this.system].params[param].subparams[subparam].name, false, schema.eco_efficiency[this.system].params[param].subparams[subparam].icon));
        });
      }
    }

    if (schema.functioning) {
      this.domains.push('functioning');
      const functioning = this.menuItem('functioning', 'functioning', 'functioning');
      this.menu.push(functioning);

      for (const param in schema.functioning.params) {
        const id = `functioning/${param}`;

        const paramMenu = this.menuItem(id, param, param, true, null, schema.functioning.params[param].color);
        functioning.children.push(paramMenu);

        Object.keys(schema.functioning.params[param].subparams).map(subparam => {
          const id = `functioning/${param}/${subparam}`;
          paramMenu.children.push(this.menuItem(id, subparam, schema.functioning.params[param].subparams[subparam].name, false, schema.functioning.params[param].subparams[subparam].icon));
        });
      }
    }
  }

  navigate(params: any) {
    this.router.navigate([], { queryParamsHandling: 'merge', queryParams: params });
  }

  private menuItem(id, label, name, collapsed = false, icon = null, color = '#f5a623') {
    return { id, label, name, collapsed, icon, color, children: [] };
  }

  setPeriod(period: string) {
    this.navigate({ period });
  }

  setFrom($event: NgbDate) {
    this.navigate({ from: `${$event.day}/${$event.month}/${$event.year}` });
  }

  setUntil($event: NgbDate) {
    this.navigate({ until: `${$event.day}/${$event.month}/${$event.year}` });
  }

  loadUsers() {
    return this.users.length ? of([]) :
      combineLatest([this.dataService.getProjectRolesUsersWithSystemType(this.system), this.dataService.getProjectGroups()]).pipe(
        tap(([users, groups]) => {
          this.users = users.filter(u => !u.deactivated_since);
          this.groups = groups;
        })
      );
  }


  setTarget($event: NgbTypeaheadSelectItemEvent) {
    if ($event) {
      this.navigate($event.item.value)
    } else {
      this.navigate({ user_id: null, community_id: null })
    }
  }

  setTargetUser(user) {
    if (user) {
      this.target = { prefix: 'User', label: `${ user.system.teltonika && user.system.teltonika.short_name && user.system.teltonika.short_name.length > 0 ? user.system.teltonika.short_name : (user.first_name || '') + ' ' + (user.last_name || '') }`, value: { community_id: null, user_id: user.user_id } };
      this.navigate({ user_id: user.user_id, community_id: null });
    } else {
      this.navigate({ user_id: null, community_id: null })
    }
  }

  set type(value: string) {
    if (this._type != value) {
      this.navigate({ type: value, id: null, domain: null });
    }
  }

  get type(): string {
    return this._type;
  }

  get typeLabel(): string {
    return {
      'general': 'General Indicators',
      'domain': 'Domain Scores',
      'videos': 'Videos'
    }[this._type];
  }

  set domain(value: string) {
    if (this._domain != value) {
      this.navigate({ type: 'domain', id: null, domain: value });
    }
  }

  get domain(): string {
    return this._domain;
  }

  compare(a, b) {
    return a > b ? 1 : a == b ? 0 : -1;
  }

  dateToNum(d) {
    d = d.split("/"); return Number(d[2] + d[1] + d[0]);
  }

  dateCompare(a, b) {
    const dateObjectA = Date.parse(a);
    const dateObjectB = Date.parse(b);
    return dateObjectA > dateObjectB ? 1 : dateObjectA == dateObjectB ? 0 : -1;
  }

  sortItems(column, asc, array) {
    let starredArray = this.staredOnly ? array.filter((a) => a.starred) : array;
    if (this.filterLegends.length > 0) {
      starredArray = starredArray.filter(sa => {
        const existsp = this.filterLegends.find(fl => {
          const spFl = fl.split('/');
          const param = spFl.length > 1 ? spFl[1] : ''
          const subparam = spFl.length > 2 ? spFl[2] : '';
          return sa.subparam === subparam && sa.param === param
        })
        return existsp ? true : false;
      })
    }
    return starredArray.sort((a, b) => {
      if (!a[column]) {
        a[column] = '';
      }
      if (!b[column]) {
        b[column] = '';
      }
      if (column == 'type') {
        return this.compare(a['subparam'], b['subparam']) * asc;
      }
      if (column == 'durationStr') {
        return this.compare(a['duration'], b['duration']) * asc;
      }
      else if (column == 'timeOfEvent') {
        return this.dateCompare(a['video_start_date'], b['video_start_date']) * asc;
      }
      else if (column == 'level') {
        return this.compare(`${a[column]}`, `${b[column]}`) * asc;
      }
      else {
        return a[column].localeCompare(b[column]) * asc;
      }
    });
  }

  toggleSort(field: string) {
    if (this.sort.column != field) {
      this.sort.column = field;
      this.sort.asc = 1;
    } else {
      this.sort.asc *= -1;
    }
  }

  sortUser(array) {
    return array.sort((a, b) => {
      if (a.first_name === 'bart' && a.last_name === 'devos') {
        return -1;
      }
      if (b.first_name === 'bart' && b.last_name === 'devos') {
        return 1;
      }
      if (a.first_name + a.last_name < b.first_name + b.last_name) {
        return -1;
      }
      if (a.first_name + a.last_name > b.first_name + b.last_name) {
        return 1;
      }
      return 0;
    });
  }

  openVideoInfo(video) {
    const ref = this.modal.open(VideoInfoComponent, { windowClass: 'videoInfoModal' });
    ref.componentInstance.video = video;
    ref.componentInstance.userId = this.selUserId;
    ref.componentInstance.menu = this.menu;
    ref.componentInstance.videoUpdate.subscribe((result) => {
      this.getVideos();
    })
  }
}
