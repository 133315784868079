<div class="container">
  <div class="top-nav-bar">
    <div class="top-nav-barmenu">
      <a [ngClass]="{'active': indgroup === 1}" (click)="navigateToUrl('individual')" translate>Individuals</a>
      <a [ngClass]="{'active': indgroup === 2}" (click)="navigateToUrl('group')" translate>Groups</a>
    </div>
  </div>
  <div class="shadowed-block">

    <div class="toolbar">
      <h2 translate>{{indgroup == 1 ? 'Drivers' : 'Groups'}}</h2>
      <div *ngIf="indgroup==1" ngbDropdown class="d-inline-block custom_dropdown">
        <button class="btn" id="dropdownBasic1" ngbDropdownToggle>{{domain|t}}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button *ngFor="let d of domains" (click)="setDomain(d)" ngbDropdownItem>{{d|t}}</button>
        </div>
      </div>
      <div ngbDropdown class="custom_dropdown">
        <button class="btn" ngbDropdownToggle>{{(items.length ? driverLabel : 'Anonymous User') | translate}}</button>
        <div ngbDropdownMenu class="max-height-350 overflow-y-auto">
          <button ngbDropdownItem (click)="filterAll()" class="pl-2"><strong translate>All Drivers</strong></button>
          <button ngbDropdownItem (click)="filterByGroups()" class="pl-2"><strong translate>Groups</strong></button>
          <button ngbDropdownItem *ngFor="let group of sortGroup(groups)" (click)="filterByGroup(group)" class="pl-4">
            {{ group.name }}
          </button>
          <button ngbDropdownItem (click)="filterByTags()" class="pl-2"><strong translate>Tags</strong></button>
          <button ngbDropdownItem *ngFor="let project_tag of project_tags" (click)="filterByTag(project_tag)" class="pl-4">
            {{ project_tag.tag_name }}
          </button>
        </div>
      </div>
      <div class="filler"></div>
      <div *ngIf="indgroup==1">
        <input style="cursor: pointer; margin-right: 5px;" id="invitebox" type="checkbox" [(ngModel)]="inviteonly">
        <label style="cursor: pointer;" for="invitebox" translate>Employed only</label>

      </div>
      <div class="d-flex justify-content-between">
        <input class="searchfield" [(ngModel)]="searchfield" style=" "
          placeholder="{{ 'Search for a' | translate: {keyword: indgroup==1?'driver':'group'} }}" />
        <button *ngIf="indgroup==-10" class="btn btn-sm btn-warning text-white mr-0 px-2 nowrap"
          (click)="addDriverPopup()">
          +&nbsp;<span translate>Invite Driver</span>

        </button>
        <button *ngIf="indgroup==2" class="btn btn-sm btn-warning btn-add text-white mr-0 px-2 nowrap"
          (click)="addGroupPopup()">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>


    <div class="tablescroll" style="">
      <table *ngIf="indgroup==1">
        <thead>
          <tr>
            <th style="cursor: pointer" (click)="toggleSort('drivers','first_name', null)">
              <span translate>Driver ID</span>&nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
            </th>
            <th style="cursor: pointer" (click)="toggleSort('drivers','trucker_type', null)"><span translate>Transport
                Type</span> <i class="fa fa-sort" aria-hidden="true"></i></th>
            <th><span translate>Group</span></th>
            <th><span translate>Distance (km)</span></th>
            <th><span translate>Time (hrs)</span></th>
            <th><span translate>Trips</span></th>
            <th><span translate>Credits</span></th>
            <th style="cursor: pointer" (click)="toggleSort('drivers','first_login', null)">
              <span translate>First Login</span>&nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
            </th>
            <th style="cursor: pointer" (click)="toggleSort('drivers','last_login', null)">
              <span translate>Last Seen</span>&nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of filterItems(lists) | paginate: { itemsPerPage: pageSize,currentPage: p}"
            [ngClass]="{'tr-disabled': item.deactivated_since}">
            <td>
              <div style="display: flex;">

                <img style="flex:0;width: 50px; height: 50px;border-radius: 50%" src="/assets/images/face-1.png">
                <div style="flex: 1;display: flex;flex-direction: column; justify-content: center;">
                  <div style="cursor: pointer;" [routerLink]="['/results/trips']"
                    [queryParams]="{user_id: item.user_id}">
                    {{ item.system.teltonika && item.system.teltonika.short_name && item.system.teltonika.short_name.length > 0 ? item.system.teltonika.short_name : (item.first_name || '') + ' ' + (item.last_name || '') }}
                  </div>
                  <div style="color: #7e7e7e">
                    {{item.system?.system_type && item.system[item.system?.system_type]?.driver_id ? 'ID #' +
                    item.system[item.system?.system_type]?.driver_id : ''}}
                  </div>
                </div>
              </div>
            </td>
            <td style="font-weight: 300">{{item.profile?.trucker_type}}</td>
            <td>{{item.communities?.length ? item.communities[0].community_name : ''}}</td>
            <td>{{item.stats?.mileage || 0}}</td>
            <td>{{item.duration}}</td>
            <td>{{item.stats?.num_trips || 0}}</td>
            <td>{{item.credits || 0}}</td>
            <td>{{item.first_login || ''}}</td>
            <td>{{item.last_login || ''}}</td>
            <td>
              <div>
                <img *ngIf="system !== 'cardio'" class="m-2 pointer" src="assets/images/legend.svg"
                  (click)="levelsModal(item)">
                <img class="m-2 pointer" src="assets/images/winbadge.svg" (click)="badgesModal(item)">
                <img class="m-2 pointer" src="assets/images/ic_edit.svg" (click)="editProfile(item)">
              </div>
            </td>
          </tr>

          <tr *ngIf="driverSub && !driverSub.closed">
            <td colspan="9" class="text-center blink"><span translate>Loading</span>...</td>
          </tr>
          <tr *ngIf="driverSub && driverSub.closed && !getPages()">
            <td colspan="9"><span translate>No search results found to display</span></td>
          </tr>
        </tbody>
      </table>
      <table *ngIf="indgroup==2">
        <thead>
          <tr>
            <th style="cursor: pointer; width: 300px;" (click)="toggleSort('group','name', null)"><span
                translate>Groups</span> <i class="fa fa-sort" aria-hidden="true"></i></th>
            <th style="cursor: pointer; width: 300px;"><span translate>Description</span></th>
            <th style="cursor: pointer; width: 150px;"><span translate>Date</span></th>
            <th style="cursor: pointer; width: 150px;"></th>
          </tr>
        </thead>
        <tbody *ngIf="groupSub && !groupSub.closed">
          <tr>
            <td colspan="9" class="text-center blink"><span translate>Loading</span>...</td>
          </tr>
        </tbody>
        <tbody *ngIf="groupSub && groupSub.closed && getPages()">
          <tr *ngFor="let item of filterGroups() | paginate: { itemsPerPage: pageSize,currentPage: p} ">
            <td>{{item.name}}</td>
            <td>{{item.description}}</td>
            <td>{{item.created_date | date:"d MMM, yyyy"}}</td>
            <td>
              <img class="m-2 pointer" src="assets/images/ic_edit.svg" (click)="editProfile(item)">
              <img class="m-2 pointer" src="assets/images/ic_delete.svg" (click)="deleteProfile(item)">
              <!--            <img (click)="onContextMenu($event, item)" style="margin: 10px 10px 10px 20px; cursor: pointer;" src="assets/images/moreicon.svg">-->
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="groupSub && groupSub.closed && !getPages()">
          <tr>
            <td colspan="4"><span translate>No search results found to display</span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <context-menu>
      <ng-template *ngIf="indgroup==2" class="context-menu-item" contextMenuItem (execute)="viewProfile($event.item)">
        <span translate>View Proﬁle</span>
      </ng-template>
      <ng-template class="context-menu-item" contextMenuItem (execute)="editProfile($event.item)">
        <span translate>Edit</span>
      </ng-template>
      <ng-template *ngIf="indgroup==2" class="context-menu-item" contextMenuItem (execute)="deleteProfile($event.item)">
        <span translate>Delete</span>
      </ng-template>
    </context-menu>
    <div style="display: flex;align-items: center;justify-content: flex-end;" *ngIf="getPages() > 1">
      <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)"
        directionLinks="true" responsive="false" previousLabel='<' nextLabel='>'
        screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page">
      </pagination-controls>
      <div style="margin-left:10px; padding-top: 7px;padding-bottom: 16px;">{{'pagination' | translate: {currentPage: p,
        totalPage: getPages()} }}</div>
    </div>
  </div>


</div>
